import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { Box, SimpleGrid, Image, Heading, Text } from "@chakra-ui/react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BoardPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const boardMembers = data.allContentfulStyremedlem?.edges
  const intl = useIntl()
  return (
    <Layout location={location} title={siteTitle} container>
      <SEO title={intl.formatMessage({ id: "board" })} />

      <Heading size="xl" marginBottom="10">
        {intl.formatMessage({ id: "board" })}
      </Heading>
      <SimpleGrid columns={[1, 2, 3, 4]} spacing={10}>
        {boardMembers
          .sort((a, b) => a.node.priority - b.node.priority)
          .map(mem => {
            const member = mem.node
            return (
              <div key={member.name}>
                <Box
                  maxW="sm"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  boxShadow="md"
                >
                  {member.image ? (
                    <Image src={member.image?.fluid?.src} alt={mem.name} />
                  ) : (
                    <img
                      src={data.fallbackperson?.childImageSharp?.fluid.src}
                      alt={mem.name}
                    />
                  )}
                  <Box p="5">
                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                      isTruncated
                    >
                      {member.name}
                    </Box>
                    <Text>{member.title}</Text>
                    <Text color="#818181" fontSize="sm">
                      {member.subtitle}
                    </Text>
                  </Box>
                </Box>
              </div>
            )
          })}
      </SimpleGrid>
    </Layout>
  )
}

BoardPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default BoardPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    fallbackperson: file(relativePath: { eq: "fallback-person.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allContentfulStyremedlem {
      edges {
        node {
          name
          title
          subtitle
          priority
          image {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
